import React from "react";
import { Link } from "react-router-dom";

const ListProductsVerticalItem = ({ item }) => {
  const formattedPrice = (price) => {
    const parts = price.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return parts.join(",");
  };

  function formatUrlString(inputString) {
    // Pretvara sva velika slova u mala
    const lowercaseString = inputString.toLowerCase();

    // Zamjenjuje sve razmake i specijalne karaktere sa -
    const formattedString = lowercaseString
      .replace(/[^\w\s]/gi, "")
      .replace(/\s+/g, "-");

    return formattedString;
  }
  return (
    <div className="product product-widget">
      <figure className="product-media">
        <Link to={`/proizvod/${formatUrlString(item.naziv)}/${item.sifra}`}>
          <img src={item.slika} alt="Product" width="300" height="338" />
        </Link>
      </figure>
      <div className="product-details">
        <h4 className="product-name">
          <Link to={`/proizvod/${formatUrlString(item.naziv)}/${item.sifra}`}>
            {item.naziv}
          </Link>
        </h4>
        <div className="product-price product-price-holder-custom product-detail-price-custom product-item-vertical-list">
          {item.cena_sa_popustom ? (
            parseFloat(item.cena_sa_popustom) < parseFloat(item.cena) ? (
              <>
                <div className="old-price-custom-div">
                  <del>{formattedPrice(item.cena)} RSD</del>
                </div>
                <ins className="new-price new-price-custom">
                  {formattedPrice(item.cena_sa_popustom)} <small>RSD</small>
                </ins>
              </>
            ) : (
              <>
                <ins className="new-price new-price-custom">
                  {formattedPrice(item.cena_gotovinsko)} <small>RSD</small>
                </ins>
                <div className="payment-price-custom">
                  Web cena: {formattedPrice(item.cena_sa_popustom)}{" "}
                  <small>RSD</small>
                </div>
              </>
            )
          ) : parseFloat(item.cena_sa_popustom) < parseFloat(item.cena) ? (
            <>
              <div className="old-price-custom-div">
                <del>{formattedPrice(item.cena)} RSD</del>
              </div>
              <ins className="new-price new-price-custom">
                {formattedPrice(item.cena_sa_popustom)} <small>RSD</small>
              </ins>
            </>
          ) : (
            <>
              {item.cena > 1000 ? (
                <ins className="new-price new-price-custom">
                  {formattedPrice(item.cena_gotovinsko)} <small>RSD</small>
                </ins>
              ) : (
                <ins className="new-price new-price-custom">
                  {formattedPrice(item.cena)} <small>RSD</small>
                </ins>
              )}
              {item.cena > 1000 && (
                <div className="payment-price-custom">
                  Web cena: {formattedPrice(item.cena)} <small>RSD</small>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ListProductsVerticalItem;
