import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";

const ListProductsItem = ({ item }) => {
  const formattedPrice = (price) => {
    const parts = price.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return parts.join(",");
  };

  function formatUrlString(inputString) {
    // Pretvara sva velika slova u mala
    const lowercaseString = inputString.toLowerCase();

    // Zamjenjuje sve razmake i specijalne karaktere sa -
    const formattedString = lowercaseString
      .replace(/[^\w\s]/gi, "")
      .replace(/\s+/g, "-");

    return formattedString;
  }
  return (
    <>
      {item && (
        <div className="product product-slideup-content product-custom">
          <figure className="product-media">
            <Link
              to={`/proizvod/${formatUrlString(
                item.naziv
              )}/${encodeURIComponent(item.sifra)}`}
            >
              <img
                src={
                  item.slike[0] ? item.slike[0] : "/images/no-image-product.png"
                }
                alt="Product"
                className="img-fluid img-product-list-custom"
                width="295"
                height="335"
              />
            </Link>
            <div className="product-label-group">
              {item.new == 1 && (
                <label className="product-label label-new">Novo</label>
              )}

              {item.izdvojeno == 1 && (
                <label className="product-label label-new">Izdvojeno</label>
              )}
              {item.popularno == 1 && (
                <label className="product-label label-hot">Popularno</label>
              )}
              {item.mesecna_akcija == 1 && (
                <label className="product-label label-discount">
                  % Mesečna akcija
                </label>
              )}
              {item.akcija == 1 && (
                <label className="product-label label-discount">% Akcija</label>
              )}
              {item.ponovo_na_stanju == 1 && (
                <label className="product-label label-hot">
                  Najprodavanije
                </label>
              )}
              {item.preporucujemo == 1 && (
                <label className="product-label label-hot">Preporučujemo</label>
              )}
              {parseFloat(item.cena_sa_popustom) < parseFloat(item.cena) && (
                <label className="product-label label-discount">
                  % NA POPUSTU
                </label>
              )}
            </div>
            {/* <div className="product-action-vertical">
          <a
            className="btn-product-icon btn-quickview w-icon-search"
            title="Quick View"
          ></a>
        </div> */}
          </figure>
          <div className="product-details">
            <div className="product-cat">
              <Link to={`/katalog/nadgrupa/${item.nadgrupa}?page=1`}>
                {item.nadgrupa} -{" "}
              </Link>
              <Link to={`/katalog/grupa/${item.grupa}?page=1`}>
                {" "}
                {item.grupa}
              </Link>
            </div>
            <h3 className="product-name product-name-custom">
              <Link
                to={`/proizvod/${formatUrlString(
                  item.naziv
                )}/${encodeURIComponent(item.sifra)}`}
              >
                {item.naziv}
              </Link>
            </h3>
            <div className="product-price product-price-holder-custom">
              {item.cena_sa_popustom ? (
                parseFloat(item.cena_sa_popustom) < parseFloat(item.cena) ? (
                  <>
                    <div className="old-price-custom-div">
                      <del>{formattedPrice(item.cena)} RSD</del>
                    </div>
                    <ins className="new-price new-price-custom">
                      {formattedPrice(item.cena_sa_popustom)} <small>RSD</small>
                    </ins>
                  </>
                ) : (
                  <>
                    {item.cena > 1000 ? (
                      <ins className="new-price new-price-custom">
                        {formattedPrice(item.cena_gotovinsko)}{" "}
                        <small>RSD</small>
                      </ins>
                    ) : (
                      <ins className="new-price new-price-custom">
                        {formattedPrice(item.cena)} <small>RSD</small>
                      </ins>
                    )}
                    {item.cena > 1000 && (
                      <div className="payment-price-custom">
                        Web cena: {formattedPrice(item.cena)} <small>RSD</small>
                      </div>
                    )}
                  </>
                )
              ) : parseFloat(item.cena_sa_popustom) < parseFloat(item.cena) ? (
                <>
                  <div className="old-price-custom-div">
                    <del>{formattedPrice(item.cena)} RSD</del>
                  </div>
                  <ins className="new-price new-price-custom">
                    {formattedPrice(item.cena_sa_popustom)} <small>RSD</small>
                  </ins>
                </>
              ) : (
                <>
                  {item.cena > 1000 ? (
                    <ins className="new-price new-price-custom">
                      {formattedPrice(item.cena_gotovinsko)} <small>RSD</small>
                    </ins>
                  ) : (
                    <ins className="new-price new-price-custom">
                      {formattedPrice(item.cena)} <small>RSD</small>
                    </ins>
                  )}
                  {item.cena > 1000 && (
                    <div className="payment-price-custom">
                      Web cena: {formattedPrice(item.cena)} <small>RSD</small>
                    </div>
                  )}
                </>
              )}
            </div>

            {/* <div className="product-price product-price-holder-custom">
              {item.cena_sa_popustom &&
                parseFloat(item.cena_sa_popustom) < parseFloat(item.cena) && (
                  <div className="old-price-custom-div">
                    <del>{formattedPrice(item.cena)} RSD</del>
                  </div>
                )}
              <ins className="new-price new-price-custom">
                {item.cena_sa_popustom
                  ? formattedPrice(item.cena_sa_popustom)
                  : formattedPrice(item.cena)}{" "}
                <small>RSD</small>
              </ins>
              {(item.cena_sa_popustom === null ||
                parseFloat(item.cena_sa_popustom) > parseFloat(item.cena)) && (
                <div className="payment-price-custom">
                  Cena gotovisnko: {formattedPrice(item.cena_gotovinsko)}{" "}
                  <small>RSD</small>
                </div>
              )}
            </div> */}
          </div>
          <div className="product-hidden-details">
            <div className="product-action">
              <Link
                to={`/proizvod/${formatUrlString(
                  item.naziv
                )}/${encodeURIComponent(item.sifra)}`}
                className="btn-product btn-cart w-100"
                title="Pogledaj"
              >
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  style={{ marginRight: "5px" }}
                />
                <span>Pogledaj detaljnije</span>
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ListProductsItem;
