import React from "react";
import { Helmet } from "react-helmet-async";

const HelmetSeo = (props) => {
  const {
    title,
    description,
    keywords,
    ogType,
    ogTitle,
    ogDescription,
    ogImage,
    ogUrl,
    ogsiteName,
    ogImageWidth,
    ogImageHeight,
  } = props || {};

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      {ogType && <meta property="og:type" content={ogType} />}
      {ogTitle && <meta property="og:title" content={ogTitle} />}
      {ogDescription && (
        <meta property="og:description" content={ogDescription} />
      )}
      {ogImage && <meta property="og:image" content={ogImage} />}
      {ogUrl && <meta property="og:url" content={ogUrl} />}
      {ogsiteName && <meta property="og:site_name" content={ogsiteName} />}
      {ogImageWidth && (
        <meta property="og:image:width" content={ogImageWidth} />
      )}
      {ogImageHeight && (
        <meta property="og:image:height" content={ogImageHeight} />
      )}
    </Helmet>
  );
};

export default HelmetSeo;
