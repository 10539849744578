import {
  faAngleUp,
  faBars,
  faChevronCircleDown,
  faChevronDown,
  faChevronRight,
  faList,
  faMagnifyingGlass,
  faPhone,
  faSearch,
  faShoppingBag,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { fetchCategoriesList } from "../../actions/global";
import { useSelector } from "react-redux";

const Navigation = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isClassAdded, setIsClassAdded] = useState(false);
  const [fixedMenu, setFixedMenu] = useState(false);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 800) {
        setFixedMenu(true);
      } else {
        setFixedMenu(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const { dateCategoriesList } = useSelector((state) => state.globalReducer);
  // Provera da li je trenutna ruta "/"
  const isHome = location.pathname === "/";
  const [searchText, setSearchText] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    if (searchText.length >= 3) {
      // Poziv funkcije za pretragu
      navigate(`/pretraga?pojam=${searchText}`);
      setSearchText("");
    } else {
      alert("Morate uneti bar tri karaktera za pretragu.");
    }
  };

  const handleChange = (event) => {
    setSearchText(event.target.value);
  };

  const [showMenu, setShowMenu] = useState("");

  useEffect(() => {
    if (isHome) {
      setShowMenu("show-dropdown");
    } else {
      setShowMenu("");
    }
  }, [location]);

  useEffect(() => {
    // alert(1);
    dispatch(fetchCategoriesList());
  }, []);

  useEffect(() => {
    if (isClassAdded) {
      document.body.classList.add("mmenu-active");
    } else {
      document.body.classList.remove("mmenu-active");
    }
  }, [isClassAdded]);

  const handleMouseEnter = () => {
    setIsActive(true);
  };

  const handleMouseLeave = () => {
    setIsActive(false);
  };

  const toggleClass = () => {
    setIsClassAdded((prevState) => !prevState);
  };

  const handleCategoryClick = () => {
    setIsActive(false);
  };

  return (
    <header className="header has-center">
      <div className="header-top">
        <div className="container">
          <div className="header-left">
            <p className="welcome-msg">
              Dobrodošli na UP-IT shop,{" "}
              <a
                href="https://www.up-it.rs"
                target="_blank"
                style={{ textDecoration: "underline" }}
              >
                posetite i našu web prezentaciju
              </a>
            </p>
          </div>
          <div className="header-right">
            <Link to="/kontakt">Kontakt</Link>
          </div>
        </div>
      </div>

      <div className="header-middle">
        <div className="container">
          <div className="header-left mr-md-4">
            <FontAwesomeIcon
              icon={faBars}
              className="mobile-menu-toggle"
              onClick={toggleClass}
              style={{ fontSize: "25px", marginRight: "15px" }}
            />
            <Link to="/" className="logo ml-lg-0">
              <img
                src="/images/logo_03.png"
                alt="logo"
                width="144"
                height="45"
              />
            </Link>
          </div>

          <div className="header-center">
            <nav className="main-nav ml-4">
              <ul className="menu active-underline">
                <li className="active">
                  <Link to="/">Početna</Link>
                </li>
                <li>
                  <Link to="/izdvajamo-iz-ponude">Izdvajamo iz ponude</Link>
                </li>
                <li>
                  <Link to="/popularno">Popularno</Link>
                </li>
                <li>
                  <Link to="/mesecna-akcija">Mesečna akcija</Link>
                </li>
                <li>
                  <Link to="/preporucujemo">Preporučujemo</Link>
                </li>
                <li>
                  <Link to="/najprodavanije">Najprodavanije</Link>
                </li>
              </ul>
            </nav>
          </div>

          <div className="header-right">
            <div className="header-call d-lg-flex align-items-center">
              <FontAwesomeIcon
                icon={faPhone}
                style={{ marginRight: "8px", fontSize: "25px" }}
                className="d-lg-show"
              />
              <div className="call-info">
                <h4 className="chat font-weight-normal font-size-md text-normal ls-normal text-light mb-0">
                  Kontaktirajte nas:
                </h4>
                <a
                  href="tel:+38162208684"
                  className="phone-number font-weight-bolder ls-50"
                >
                  062/20-86-84
                </a>
                <br />
                <a
                  href="tel:+38162639796"
                  className="phone-number font-weight-bolder ls-50"
                >
                  062/63-97-96
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="header-search-mobile">
        <form
          onSubmit={handleSubmit}
          className="header-search hs-expanded hs-round bg-white br-xs d-md-flex input-wrapper mr-4 ml-4"
        >
          <input
            value={searchText}
            onChange={handleChange}
            type="text"
            className="form-control text-light border-no"
            name="search"
            id="search"
            placeholder="Pronađi željeni proizvod..."
            required
          />
          <button className="btn btn-search border-no" type="submit">
            <FontAwesomeIcon icon={faSearch} />
          </button>
        </form>
      </div>

      <div
        className={`header-bottom sticky-content fix-top sticky-header has-dropdown ${
          fixedMenu && "fixed"
        }`}
      >
        <div className="container">
          <div className="inner-wrap">
            <div className="header-left  flex-1">
              <div
                class={`dropdown category-dropdown ${showMenu} ${
                  isActive ? "activeMenu" : ""
                }`}
                data-visible="true"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <a
                  className="category-toggle"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="true"
                  data-display="static"
                  title="Browse Categories"
                >
                  <FontAwesomeIcon icon={faList} />
                  <span style={{ fontWeight: "normal" }}>KATEGORIJE</span>
                  <FontAwesomeIcon icon={faChevronDown} />
                </a>

                <div class={`dropdown-box `}>
                  <ul className="menu vertical-menu category-menu">
                    {dateCategoriesList?.slice(0, 11).map((item, key) => (
                      <li key={key}>
                        <Link
                          onClick={handleCategoryClick}
                          to={`/katalog/nadgrupa/${
                            encodeURIComponent(item.nadgrupa) || "Ostalo"
                          }?page=1`}
                        >
                          {item.nadgrupa || "Ostalo"}
                        </Link>
                        <ul className="megamenu">
                          <li>
                            <ul>
                              {item.grupe?.map((item2, key2) => (
                                <li key={key2}>
                                  <Link
                                    to={`/katalog/grupa/${encodeURIComponent(
                                      item2
                                    )}?page=1`}
                                    onClick={handleCategoryClick}
                                  >
                                    {item2}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </li>
                        </ul>
                      </li>
                    ))}

                    <li>
                      <Link
                        to="/kategorije"
                        className="font-weight-bold text-uppercase ls-25 font-custom-category-all-menu"
                      >
                        Pogledajte sve kategorije
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          style={{ marginLeft: "10px" }}
                        />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              <form
                onSubmit={handleSubmit}
                className="header-search hs-expanded hs-round bg-white br-xs d-md-flex input-wrapper mr-4 ml-4"
              >
                <input
                  value={searchText}
                  onChange={handleChange}
                  type="text"
                  className="form-control text-light border-no"
                  name="search"
                  id="search"
                  placeholder="Pronađi željeni proizvod..."
                  required
                />
                <button className="btn btn-search border-no" type="submit">
                  <FontAwesomeIcon icon={faSearch} />
                </button>
              </form>
            </div>
            <div className="header-right  header-right-custom">
              {/* <a  className="d-xl-show">
                <i className="w-icon-map-marker mr-1"></i>Track Order
              </a> */}
              <Link to="/specijalna-akcija">
                <FontAwesomeIcon
                  icon={faShoppingBag}
                  style={{ marginRight: "5px", fontSize: "15px" }}
                />
                {"  "}SPECIJALNA AKCIJA
              </Link>
            </div>
          </div>
        </div>
      </div>

      <a
        id="scroll-top"
        className="scroll-top"
        href="#top"
        title="Top"
        role="button"
      >
        <FontAwesomeIcon icon={faAngleUp} style={{ fontSize: "15px" }} />
      </a>

      <div className="mobile-menu-wrapper">
        <div className="mobile-menu-overlay" onClick={toggleClass}></div>

        <a className="mobile-menu-close" onClick={toggleClass}>
          <i className="close-icon"></i>
        </a>

        <div className="mobile-menu-container scrollable">
          <div className="tab-content">
            <div className="tab-pane active" id="main-menu">
              <ul className="mobile-menu" onClick={toggleClass}>
                {dateCategoriesList?.slice(0, 11).map((item, key) => (
                  <li key={key}>
                    <Link
                      to={`/katalog/nadgrupa/${
                        item.nadgrupa || "Ostalo"
                      }?page=1`}
                      onClick={handleCategoryClick}
                    >
                      {item.nadgrupa || "Ostalo"}
                    </Link>
                    <ul className="megamenu">
                      <li>
                        <ul>
                          {item.grupe?.map((item2, key2) => (
                            <li key={key2}>
                              <Link
                                to={`/katalog/grupa/${item2}?page=1`}
                                onClick={handleCategoryClick}
                              >
                                {item2}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                    </ul>
                  </li>
                ))}
                <li>
                  <Link
                    to="/kategorije"
                    className="font-weight-bold text-uppercase ls-25 font-custom-category-all-menu"
                  >
                    Pogledajte sve kategorije
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      style={{ marginLeft: "10px" }}
                    />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navigation;
